import React from "react";
import CMOButton from "../../components/Button/Button";
import LayoutHeaderFooter from "../../components/LayoutHeaderFooter/LayoutHeaderFooter";
import "./productCategory.scss";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

class ProductCategory extends React.Component {

  render() {
    const {
      products,
      Seo,
      title,
      slug,
      blogposts,
      topCardText,
      categoryDescriptionContent,
      topCardImage,
      topCardImageAlt,
    } = this.props.pageContext.content;

    const location_href = this.props.location.pathname;
    const prodBuild = this.props.pageContext.prodBuild;

    let filteredProducts = products;
    if( prodBuild )
    {
      filteredProducts = products.filter( p => p.IsActiveOnProduction );
    }

    return (
      <LayoutHeaderFooter
        header_background_color={"#5107FF"}
        header_secondary_color={"#5107FF"}
        header_no_fade={true}
        SEO_title={Seo.title}
        SEO_description={Seo.description}
        SEO_keywords={Seo.keywords}
        SEO_location={location_href}
        SEO_image={Seo.image}
        main_class={"product-category-page"}
      >
        <h1>{title}</h1>
        <div className="top-card">
          <div className="text">
            <ReactMarkdown
              rehypePlugins={[rehypeRaw]}
              children={topCardText}
            ></ReactMarkdown>
          </div>
          <img
            src={`${process.env.GATSBY_CMS_URL}${topCardImage?.url}`}
            alt={topCardImageAlt}
          />
        </div>
        <div
          className={
            filteredProducts.length === 1
              ? "related-products one-column"
              : filteredProducts.length === 2
              ? "related-products two-columns"
              : "related-products three-columns"
          }
        >
          {filteredProducts.map((relatedProduct, index) => (
            <a
              href={slug + relatedProduct.Slug}
              className="related-product"
              key={`related-product-${index}`}
            >
              <img
                alt={relatedProduct.title}
                className="related-product-image"
                src={
                  process.env.GATSBY_CMS_URL +
                  relatedProduct.ImageColorVariant[0].image.url
                }
                title={relatedProduct.ImageColorVariant[0].image_description }
              ></img>
              <span className="related-product-name">
                {relatedProduct.title + ( !relatedProduct.IsActiveOnProduction ? " - Nem aktív élesen!!!" : "" )  } 
              </span>
              <span className="related-product-description">
                {relatedProduct.carouselDescription}
              </span>
              <span className="related-product-price">
                {relatedProduct.carouselPrice}
              </span>
              <span className="related-product-price-gross">
                {relatedProduct.carouselPriceGross}
              </span>
              <CMOButton
                variant="contained"
                background_color={"#5107ff"}
                text={"Válaszd ezt!"}
                color={"white"}
              ></CMOButton>
              <ul className="bullet-points">
                {relatedProduct.carouselBulletPoint.map(
                  (bulletPoint, index) => (
                    <li className="bullet-point" key={`bullet-point-${index}`}>
                      {bulletPoint.text}
                    </li>
                  )
                )}
              </ul>
            </a>
          ))}
        </div>
        {
          categoryDescriptionContent 
                    ?
                    <div style={{width: "100%"}}>
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={categoryDescriptionContent}
                      />
                    </div>
                    : "" }
        <h2>Kedvenc blogjaitok</h2>
        <div className="articles">
          {blogposts
            ? blogposts
                /*.sort(
              (post1, post2) =>
                new Date(post2.published_at).valueOf() -
                new Date(post1.published_at).valueOf()
            )*/
                .map((post, index) => {
                  return (
                    <a
                      href={`/blog${post.slug}`}
                      className="article"
                      key={index}
                    >
                      {post.thumbnail && (
                        <img
                          src={`${process.env.GATSBY_CMS_URL}${post.thumbnail.url}`}
                          alt={post.thumbnail_alt}
                        />
                      )}
                      <div className="date">
                        {new Date(post.createdAt).toLocaleDateString("hu-HU")}
                      </div>
                      <div className="reading_time">
                        Olvasási idő: {post.reading_time}
                      </div>
                      <div className="article_title">{post.title}</div>
                      <div className="article_desc">
                        {post.short_description}
                      </div>
                    </a>
                  );
                })
            : ""}
        </div>
      </LayoutHeaderFooter>
    );
  }
}

export default ProductCategory;
